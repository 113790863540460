.card {
    margin: 8px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.4) -1px 1px 3px;
}

* {
    font-family: 'Source Sans Pro', sans-serif
}

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
}

@tailwind base;
@tailwind components;
@tailwind utilities;